import React from "react";

const Home = () => (
  <div>
    <p>&gt; I should really put some content here... too busy right now :-)</p>
    <p>
      &gt; Just moved site to{" "}
      <a href="https://www.cloudflare.com">Cloudflare</a>, super easy!
    </p>
  </div>
);

export default Home;
