export const loadObjectFromStorage = (key, defaults = false) => {
  try {
    const savedJson = window.localStorage.getItem("formValues");
    if (typeof savedJson != "string") {
      return false;
    }
    const loaded = JSON.parse(savedJson);
    if (!defaults) {
      return loaded;
    }
    return Object.keys(defaults).reduce((o, key) => {
      o[key] = loaded.hasOwnProperty(key) ? loaded[key] : defaults[key];
      return o;
    }, {});
  } catch {
    return false;
  }
};

export const saveObjectToStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch {
    return false;
  }
};
