import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Home from "./containers/Home";
import Links from "./containers/Links";
import ImageToHex from "./containers/ImageToHex";
import Plasma from "./containers/Plasma";

function App() {
  return (
    <Router>
      <h1>
        <Link to="/">PAB.me.uk</Link>
      </h1>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/links" element={<Links />} />
        <Route path="/image-to-hex" element={<ImageToHex />} />
        <Route path="/plasma" element={<Plasma />} />
      </Routes>
    </Router>
  );
}

export default App;
