import React, { useCallback } from "react";

const ImageUpload = ({ onUpload }) => {
  const onChange = useCallback(
    (event) => {
      const [file] = event.target.files;
      if (file) {
        const reader = new FileReader();
        // const { current } = uploadedImage;
        // current.file = file;
        reader.onload = (event) => {
          const image = new Image();
          image.onload = () => {
            onUpload(image);
          };
          image.src = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    [onUpload]
  );

  return (
    <input type="file" accept="image/*" multiple={false} onChange={onChange} />
  );
};

export default ImageUpload;
