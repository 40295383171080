import React, { useCallback, useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import copy from "copy-to-clipboard";

import ImageUpload from "./ImageUpload";
import ImageZoom from "../components/ImageZoom";

import imageToCodeFunctionMap from "../lib/imageToCodeFunctionMap";
import { loadObjectFromStorage, saveObjectToStorage } from "../lib/storage";

const isAllSameColor = (imageData) =>
  !imageData.data.some(
    (value, index) => index > 3 && value !== imageData.data[index - 4]
  );

const processImage = (
  context,
  imageWidth,
  imageHeight,
  { spriteWidth, spriteHeight, spriteOffsetX, spriteOffsetY }
) => {
  const processed = [];

  for (let y = spriteOffsetY; y < imageHeight; y += spriteHeight) {
    for (let x = spriteOffsetX; x < imageWidth; x += spriteWidth) {
      const imageData = context.getImageData(x, y, spriteWidth, spriteHeight);
      processed.push({
        x,
        y,
        imageData: imageData,
        empty: isAllSameColor(imageData),
      });
    }
  }

  return processed.filter((item) => !item.empty);
};

const defaultFormValues = {
  codeFormat: "32f",
  individualPalettes: true,
  reverseEvenLines: true,
  spriteWidth: 16,
  spriteHeight: 16,
  spriteOffsetX: 0,
  spriteOffsetY: 0,
};

const ImageToHex = () => {
  const [image, setImage] = useState();
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [code, setCode] = useState();
  const [fullImageData, setFullImageData] = useState();
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [processed, setProcessed] = useState();
  const refCanvas = useRef();

  useEffect(() => {
    const savedFormValues = loadObjectFromStorage(
      "formValues",
      defaultFormValues
    );
    if (savedFormValues) {
      setFormValues(savedFormValues);
    }
  }, []);

  const onUpload = useCallback((image) => setImage(image), [setImage]);

  const onFormItemChange = useCallback(
    (event) => {
      const { name, value, checked, type } = event.target;
      let resolvedValue;
      switch (type) {
        case "checkbox":
          resolvedValue = checked;
          break;
        case "number":
          resolvedValue = value | 0;
          break;
        default:
          resolvedValue = value;
          break;
      }
      if (["spriteWidth", "spriteHeight"].includes(name) && resolvedValue < 1) {
        resolvedValue = 1;
      }
      const newFormValues = Object.assign({}, formValues, {
        [name]: resolvedValue,
      });
      setFormValues(newFormValues);
      saveObjectToStorage("formValues", newFormValues);
    },
    [formValues]
  );

  useEffect(() => {
    if (!image) return;
    const { current: canvas } = refCanvas;
    const context = canvas.getContext("2d");
    const { width, height } = image;
    canvas.width = width;
    canvas.height = height;
    context.drawImage(image, 0, 0);
    setFullImageData(context.getImageData(0, 0, width, height));
    setProcessed(processImage(context, width, height, formValues));
  }, [image, formValues, refCanvas]);

  const copyCodeToClipboard = useCallback(
    (index) => {
      setShowCodeModal(true);
      const { imageData, x, y } = processed[index];
      const code = imageToCodeFunctionMap[formValues.codeFormat]({
        ...{
          imageData,
          fullImageData,
          spriteX: x,
          spriteY: y,
          multiple: false,
          first: true,
        },
        ...formValues,
      });
      setCode(code);
      console.log(code);
    },
    [formValues, fullImageData, processed]
  );

  const copyAllCodeToClipboard = useCallback(
    (index) => {
      setShowCodeModal(true);
      const code = processed
        .map((item, index) => {
          const { imageData, x, y } = item;
          return imageToCodeFunctionMap[formValues.codeFormat]({
            ...{
              imageData,
              fullImageData,
              spriteX: x,
              spriteY: y,
              multiple: true,
              first: index === 0,
            },
            ...formValues,
          });
        })
        .join("\n\n");
      setCode(code);
      console.log(code);
    },
    [formValues, fullImageData, processed]
  );

  const closeModal = useCallback(() => {
    setShowCodeModal(false);
    setCode(false);
  }, []);

  const copyCode = useCallback(() => {
    copy(code, { format: "text/plain" });
    setShowCodeModal(false);
    setCode(false);
  }, [code]);

  Modal.defaultStyles.overlay.backgroundColor = "rgba(255, 255, 255, 0.25)";
  Modal.defaultStyles.content = {
    ...Modal.defaultStyles.content,
    ...{
      background: "#222",
      color: "#ffb000",
      display: "flex",
      flexDirection: "column",
    },
  };

  const codeStyle = {
    padding: "10px",
    marginTop: 0,
    overflow: "auto",
    flex: 1,
    backgroundColor: "#000",
  };

  return (
    <>
      <h3>Image to Hex</h3>
      <p>&gt; Tool to convert images into hex data suitable for C++</p>
      <Modal
        isOpen={showCodeModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <pre style={codeStyle}>{code}</pre>
        <div style={{ textAlign: "right" }}>
          <button onClick={closeModal}>Close</button>
          <button onClick={copyCode} style={{ marginLeft: "20px" }}>
            Copy to Clipboard
          </button>
        </div>
      </Modal>
      <div className="form-fields">
        <div className="form-field">
          <label>Upload image</label>
          <ImageUpload onUpload={onUpload} />
          <canvas
            ref={refCanvas}
            style={{
              display: image ? "block" : "none",
            }}
            className="uploaded-image"
          />
        </div>
        <div className="form-field">
          <label>Code format</label>
          <select
            name="codeFormat"
            value={formValues.codeFormat}
            onChange={onFormItemChange}
          >
            <option value="32f">Full RGB as uint32_t</option>
            <option value="8wp">Bytes of palette index</option>
          </select>
        </div>
        {formValues.codeFormat === "8wp" && (
          <div className="form-field">
            <label>Individual Palettes</label>
            <input
              name="individualPalettes"
              title="Each sprite has its own palette, rather than sharing the whole images palette."
              type="checkbox"
              checked={formValues.individualPalettes}
              onChange={onFormItemChange}
              disabled={formValues.codeFormat !== "8wp"}
            />
          </div>
        )}
        <div className="form-field">
          <label>Reverse even lines</label>
          <input
            name="reverseEvenLines"
            type="checkbox"
            checked={formValues.reverseEvenLines}
            onChange={onFormItemChange}
          />
        </div>
        <div className="form-field">
          <label>Sprite Width</label>
          <input
            name="spriteWidth"
            type="number"
            value={formValues.spriteWidth}
            onChange={onFormItemChange}
          />
        </div>
        <div className="form-field">
          <label>Sprite Height</label>
          <input
            name="spriteHeight"
            type="number"
            value={formValues.spriteHeight}
            onChange={onFormItemChange}
          />
        </div>
        <div className="form-field">
          <label>Sprite Offset X</label>
          <input
            name="spriteOffsetX"
            type="number"
            value={formValues.spriteOffsetX}
            onChange={onFormItemChange}
          />
        </div>
        <div className="form-field">
          <label>Sprite Offset Y</label>
          <input
            name="spriteOffsetY"
            type="number"
            value={formValues.spriteOffsetY}
            onChange={onFormItemChange}
          />
        </div>
      </div>
      {processed && (
        <p>
          Click any image below to view code or&nbsp;
          <button onClick={copyAllCodeToClipboard} className="button-link">
            view code for all
          </button>
        </p>
      )}
      {processed &&
        processed.map(({ x, y, imageData, code, empty }, index) => (
          <div
            key={x + "," + y}
            onClick={() => copyCodeToClipboard(index)}
            className="processed-sprite"
            title={"Click to copy code to clipboard"}
          >
            <ImageZoom imageData={imageData} factor={5} />
          </div>
        ))}
    </>
  );
};

export default ImageToHex;
