import React, { useEffect, useRef } from "react";

const ImageZoom = ({ imageData, factor, ...other }) => {
  const { width, height } = imageData;
  const refCanvas = useRef();

  useEffect(() => {
    const { current: canvas } = refCanvas;
    const ctx = canvas.getContext("2d");
    ctx.putImageData(imageData, 0, 0);
  });

  const style = {
    width: width * factor,
    height: height * factor,
  };

  return (
    <canvas
      width={width}
      height={height}
      className="crisp-zoom"
      ref={refCanvas}
      style={style}
      {...other}
    />
  );
};

export default ImageZoom;
