import {
  INDENT,
  CODE_SPRITE_SUFFIX,
  // componentToHex,
  chunk,
} from "./imageToCodeCommon";

// const byteToCppHex8 = (c) => '0x' + componentToHex(c);

const hexToCodeHex32 = (hex) => "0x00000000".substr(0, 10 - hex.length) + hex;

const getSpriteCodePrefix = (x, y) =>
  `const byte spriteFromX${x}Y${y}[] PROGMEM = {\n`;

const getPalettePrefix = (x, y) =>
  `const uint32_t paletteFromX${x}Y${y}[] PROGMEM = {\n`;

const generateSpriteCode8WithPalette = ({
  imageData,
  fullImageData,
  spriteX,
  spriteY,
  reverseEvenLines,
  individualPalettes,
  multiple,
  first,
}) => {
  const palette = individualPalettes
    ? paletteFromImageData(imageData)
    : paletteFromImageData(fullImageData);

  const { data, width, height } = imageData;
  const chunkedData = chunk(data, 4);
  let code = [];

  if (individualPalettes || !multiple || (multiple && first)) {
    // Palette
    code.push(getPalettePrefix(spriteX, spriteY));
    code.push(INDENT);
    const values = palette.map((h) => hexToCodeHex32(h));
    code.push(values.join(", "));
    code.push("\n");
    code.push(CODE_SPRITE_SUFFIX);
  }

  // Sprite
  code.push(getSpriteCodePrefix(spriteX, spriteY));
  for (let y = 0; y < height; y++) {
    code.push(INDENT);
    const line = [];
    for (let x = 0; x < width; x++) {
      const rgba = chunkedData[y * width + x];
      const decimal = rgbaToDecimal(rgba).toString(16);
      const index = palette.indexOf(decimal);
      line.push(index);
    }
    if (reverseEvenLines && y % 2 === 0) {
      code.push(line.reverse().join(", "));
    } else {
      code.push(line.join(", "));
    }
    code.push(",\n");
  }
  code.push(CODE_SPRITE_SUFFIX);

  return code.join("");
};

const rgbaToDecimal = (rgba) => (rgba[0] << 16) + (rgba[1] << 8) + rgba[2];

const paletteFromImageData = (imageData) => {
  return [
    ...chunk(imageData.data, 4).reduce(
      (palette, rgba) => palette.add(rgbaToDecimal(rgba)),
      new Set()
    ),
  ].map((x) => x.toString(16));
};

export default generateSpriteCode8WithPalette;
