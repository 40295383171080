import React, { useCallback, useRef, useState, useEffect } from "react";

import ImageZoom from "../components/ImageZoom";

const WIDTH = 256;
const HEIGHT = 256;
const PIXELS = WIDTH * HEIGHT;

const imageData = new ImageData(WIDTH, HEIGHT);
const { data } = imageData;

for (let i = 0; i < PIXELS; i++) {
  data[i * 4] = 0;
  data[i * 4 + 1] = 0;
  data[i * 4 + 2] = 0;
  data[i * 4 + 3] = 255; // Opacity
}

const setRGB = (x, y, r, g, b) => {
  let offset = (y * WIDTH + x) * 4;
  data[offset++] = r;
  data[offset++] = g;
  data[offset] = b;
};

const effect1 = (time) => {
  for (let x = 0; x < WIDTH; x++) {
    for (let y = 0; y < HEIGHT; y++) {
      let v = Math.sin((x / WIDTH) * 10 + time);
      // console.log(x, y, time, v);
      // setRGB(x, y, x, y, 0);
      setRGB(x, y, v * 255, 0, 0);
    }
  }
};

const Plasma = () => {
  const [count, setCount] = useState(0);

  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = useCallback((time) => {
    if (previousTimeRef.current !== undefined) {
      // const deltaTime = time - previousTimeRef.current;

      // Pass on a function to the setter of the state
      // to make sure we always have the latest state

      // setCount((prevCount) => (prevCount + deltaTime * 0.01) % 100);
      effect1(time / 1000);
      setCount(time);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }, []);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate]); // Make sure the effect runs only once

  return (
    <>
      <h3>Plasma</h3>
      <ImageZoom imageData={imageData} factor={2} />
      <div>{count}</div>
    </>
  );
};

export default Plasma;
