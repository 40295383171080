import {
  INDENT,
  CODE_SPRITE_SUFFIX,
  componentToHex,
} from "./imageToCodeCommon";

const rgbToCppHex32 = (r, g, b) =>
  "0x00" + componentToHex(r) + componentToHex(g) + componentToHex(b);

const getSpriteCodePrefix32 = (x, y) =>
  `const uint32_t spriteFromX${x}Y${y}[] PROGMEM = {\n`;

const generateSpriteCode32 = ({
  imageData,
  spriteX,
  spriteY,
  reverseEvenLines,
}) => {
  const { data, width, height } = imageData;
  let code = [];
  code.push(getSpriteCodePrefix32(spriteX, spriteY));
  for (let y = 0; y < height; y++) {
    code.push(INDENT);
    const line = [];
    for (let x = 0; x < width; x++) {
      const i = (y * width + x) * 4;
      line.push(rgbToCppHex32(data[i], data[i + 1], data[i + 2]));
    }
    if (reverseEvenLines && y % 2 === 0) {
      code.push(line.reverse().join(", "));
    } else {
      code.push(line.join(", "));
    }
    code.push(",\n");
  }
  code.push(CODE_SPRITE_SUFFIX);
  return code.join("");
};

export default generateSpriteCode32;
