import React from "react";

import { Link } from "react-router-dom";

const Links = () => (
  <>
    <h3>Links</h3>
    <ul>
      <li>
        <Link to="/image-to-hex">Image to hex tool</Link>
      </li>
      <li>
        <Link to="/plasma">Plasma</Link>
      </li>
      <li>
        <Link to="/media/shiny-bubbles-pi-pico.mp4">
          Shiny Bubbles - Pi Pico
        </Link>
      </li>
    </ul>
  </>
);

export default Links;
